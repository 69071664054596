import logo from './logo.svg';

import { FaPlay } from "react-icons/fa";
import { FaShuffle } from "react-icons/fa6";
import SnowVid from './snow.mp4';
// import Track2 from './audio/test2.mp3';
import AudioPlayer from 'react-h5-audio-player';
import {Dropdown, Link, DropdownTrigger, DropdownMenu, DropdownItem} from "@nextui-org/react";
import {Tabs, Tab} from "@nextui-org/react";
import 'react-h5-audio-player/lib/styles.css';
import { useState, createRef } from 'react';

import {NextUIProvider} from "@nextui-org/react";
import {Button, ButtonGroup} from "@nextui-org/react";
import {Input} from "@nextui-org/react";
import {Divider} from "@nextui-org/react";
import {Select, SelectItem} from "@nextui-org/react";
import { IoMusicalNote } from "react-icons/io5"
import './App.css';

let tracks = []
let track_name = "---"
let playlists = {"Alle Lieder": []}
let playlist = []
let playlist_permutation = []
let playlist_index = 0
let playlist_name = ""
// let foundTracks = []
let player_g = createRef()


class TrackObj{
  constructor(path, audio) {
    this.path = path
    this.filename = path.split("/")[path.split("/").length-1];
    this.title = this.filename.split(".")[0];
    this.audio = audio;
  }
}

function setActiveTrack(track, setTrackName){
  console.log("setting active track to: " + track.title)

  // find playlist index
  for (let i = 0; i < playlist.length; i++){
    document.getElementById(playlist[i].title).style.color = "e5e5e5"
    document.getElementById(playlist[i].title).classList.remove("track_grad");
    if(playlist[i].title == track.title){
      // console.log("found playlist index: " + i)
      playlist_index = playlist_permutation[i]
      document.getElementById(track.title).classList.add("track_grad");
    }
  }

  // console.log(player_g.current.audio.current)
  try {
    let player = player_g.current.audio.current
    player.src = track.audio.src 
    track_name = track.title
    setTrackName(track_name)
    player.load()  
    player.play() 
  } catch{

  }

}

// function PlayButton(props){
//   // console.log(track_id["track_id"])
//   let trackobj = props["trackobj"]
//   let setTrackName = props["setTrackName"]
//   return (
//     <div class="">
//     <Button
//      isIconOnly
//      color="secondary" 
//      onClick={() => setActiveTrack(trackobj, setTrackName)}
//      size="sm"
//      >
//       <FaPlay/>
//     </Button>
//     </div>
//   )
// }

function Track( props ) {
  let trackobj = props["trackobj"]
  let setTrackName = props["setTrackName"]
  return (
      <div class="track text-lg rounded-lg flex flex-row" id={trackobj.title} onClick={() => singleSelect(trackobj, setTrackName)}><IoMusicalNote size="1.5rem"/>{trackobj.title}</div>
  );
}

let loaded = false
function loadTracks(){
  if (loaded == true){
    return
  }else{
    loaded = true
  }

  // get all audio files
  const audios = importAll(require.context('./audio', true, /\.(mp3|m4a)/));
  // console.log(audios)

  for (let path of Object.keys(audios)){

    // find playlist name
    let split = path.split("/")
    let playlist = ""
    if(split.length != 1){
      playlist = split[0]
    }
    
    // create track object
    let track = new TrackObj(path, new Audio(audios[path]))
    tracks.push(track)

    // add to playlist
    playlists["Alle Lieder"].push(track)
    if (playlist != ""){
      if (playlist in playlists){
        playlists[playlist].push(track)
      }else{
        playlists[playlist] = [track]
      }
    }

  }
  
  // console.log(playlists)

}

function importAll(r) {
  let audios = {};
  r.keys().map(item => { audios[item.replace('./', '')] = r(item); });
  return audios;
}

function playNextTrack(setTrackName){
  console.log(playlist_permutation)
  console.log("next. current position in playlist: " + playlist_index)
  
  if (playlist_index < playlist.length - 1){
    playlist_index++;
    setActiveTrack(playlist[playlist_permutation.indexOf(playlist_index)], setTrackName)
  }
}

function playPreviousTrack(setTrackName){

  console.log("prev. current position in playlist: " + playlist_index)
  if (playlist_index > 0 ){
    playlist_index--;
    setActiveTrack(playlist[playlist_permutation.indexOf(playlist_index)], setTrackName)
  }
}

function CurrentTrack(props){
  let trackName = props["trackName"]
  return(
    <h2 class="text-center">
      {trackName}
    </h2>
  )
}

function App() {
  console.log("app")
  loadTracks()

  const [searchText, setSearchText] = useState('');
  const [trackName, setTrackName] = useState('---');
  const [selectedKeys, setSelectedKeys] = useState(new Set(["Alle Lieder"]));
  
  selectPlaylist(selectedKeys, setTrackName)
  let foundTracks = filterTracks(searchText);

  return (
    <NextUIProvider>
      <div class="h-screen w-screen ">
        <PlayList selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} trackName={trackName} setTrackName={setTrackName}></PlayList>
        {/* <Divider className="" /> */}
        <TrackList setTrackName={setTrackName} foundTracks={foundTracks} searchText={searchText} setSearchText={setSearchText}></TrackList>
        
        <div class="absolute bottom-0 w-screen">
          <CurrentTrack trackName={trackName}/>
          <AudioPlayer
            // onEnded={}
            showSkipControls={true}
            showJumpControls={false}
            onClickPrevious={e => playPreviousTrack(setTrackName)}
            onClickNext={e => playNextTrack(setTrackName)}
            onEnded={e => playNextTrack(setTrackName)}
            ref={player_g}
          />
        </div>
        {/* <video autoplay muted loop id="bgvid" class="absolute bottom-0 right-0 w-screen h-screen" 
  >
          <source src={SnowVid} type="video/mp4"/>
        </video> */}
      </div>
    </NextUIProvider>
  );
}

function filterTracks(searchText){
  let filtered = []
  for (let track of playlist){
    if (track.title.toLowerCase().search(searchText.toLowerCase()) != -1){
      filtered.push(track)
    }
  }
  return filtered
}

let keys_prev = undefined
function selectPlaylist(keys, setTrackName){
  if (keys == keys_prev){
    // console.log("no real change")
    return
  }
  keys_prev = keys
  console.log("Selected Playlist: " + keys.keys().next().value)

  playlist_name = keys.keys().next().value
  playlist = playlists[playlist_name]
  playlist_permutation = [...Array(playlist.length).keys()]
  playlist_index = 0

  let track_tags = document.getElementsByClassName("track");
  for (let i = 0; i < track_tags.length; i++){
    track_tags[i].style.color = "#e5e5e5"
    track_tags[i].classList.remove("track_grad");
  }

  try {
    setTrackName("---")
    let player = player_g.current.audio.current
    player.src = ""
    player.pause()
  } catch{

  }
  // setActiveTrack(playlist[0], setTrackName)
}


function TrackList(props){
  let foundTracks = props["foundTracks"]
  let setSearchText = props["setSearchText"]
  let searchText = props["searchText"]
  let setTrackName = props["setTrackName"]

  // foundTracks = filterTracks(searchText);

  return(
    <>
      <div class="">
      {/* <Input 
          value={searchText}
          type="text"
          label="Suchen" 
          onChange={newText => setSearchText(newText.target.value)}
        
      /> */}
      </div>
      <div class="flex flex-col overflow-scroll h-3/5 gap-y-3 mx-2 my-2">
        {
          foundTracks.map(track => (
          <>
            <Track trackobj={track} setTrackName={setTrackName}/>
            {/* <PlayButton trackobj={track} setTrackName={setTrackName}/> */}
          </>
          ))
        }
      </div>
    </>
  )
}

function PlayList(props){
  let selectedKeys = props["selectedKeys"]
  let setSelectedKeys = props["setSelectedKeys"]
  let setTrackName = props["setTrackName"]

  return(
    <div class="flex flex-col w-screen">
      {/* <h2 class="col-span-1">Track:</h2>
      <h2 class="col-span-2">{trackName}</h2> */}

      <div class="flex flex-row">
      {/* <h2 class="">Playing from: </h2> */}

      <Select
        label="Playlist"
        color='secondary'
        size='lg'
        variant='underlined'
        placeholder="Select Playlist"
        disallowEmptySelection
        selectedKeys={selectedKeys}
        onSelectionChange={setSelectedKeys}
        selectionMode="single"
        className="w-screen"
      >
        {
          Object.keys(playlists).map(name => (
            <SelectItem key={name} value={name}>
            {name}
            </SelectItem>
            ))
        }
      </Select>

      {/* <Dropdown backdrop="blur">
      <DropdownTrigger>
        
          <Button 
            size='md'
            variant="bordered"
            color="secondary" 
          >
            {playlist_name}
          </Button>
      </DropdownTrigger>
      <DropdownMenu 
        variant='faded'
        disallowEmptySelection
        aria-label="Single selection example"
        selectionMode="single"
        selectedKeys={selectedKeys}
        onSelectionChange={setSelectedKeys}
        >
          {
          Object.keys(playlists).map(name => (
            <DropdownItem key={name}> {name} </DropdownItem>
            ))
          }
      </DropdownMenu>
      </Dropdown> */}
      </div>
      <div class="grid grid-cols-2 gap-5 my-2 mx-2">
        <Button
          startContent={<FaPlay/>}
          color="primary" 
          variant='bordered'
          onClick={() => startPlaylist(setTrackName)}
          size="md"
          >
            Play all
        </Button>
        <Button
          startContent={<FaShuffle/>}
          variant="solid"
          color="primary" 
          onClick={() => shufflePlaylist(setTrackName)}
          size="md"
          >
            Shuffle
        </Button>
      </div>
    </div>
  )
}

function startPlaylist(setTrackName){
  playlist_index = -1
  playlist_permutation = [...Array(playlist.length).keys()]
  playNextTrack(setTrackName)
}

function singleSelect(track, setTrackName){
  playlist_permutation = [...Array(playlist.length).keys()]
  setActiveTrack(track, setTrackName)
}
function shufflePlaylist(setTrackName){
  shuffle(playlist_permutation)
  // console.log(playlist)
  // console.log(playlist_permutation)
  playlist_index = -1
  playNextTrack(setTrackName)

}

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {

    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

export default App;
